import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import './index.module.css';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Root from "./routes/root";
import ErrorPage from "./routes/error-page";
import {ChakraProvider, extendTheme} from "@chakra-ui/react";
import LandingPage from "./routes/landing-page";
import SharePage from './routes/share';

ReactGA.initialize('G-168YT5V9KQ')

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <LandingPage />
      },
      {
        path: '/share',
        element: <SharePage />
      }
    ]
  }
])

const theme = extendTheme({
  colors: {
    highlight: {
      50: '#f2ffee',
      100: '#deffd5',
      200: '#c8ffba',
      300: '#b0fe9c',
      400: '#99fb82',
      500: '#88f86e',
      600: '#78e665',
      700: '#62cf59',
      800: '#4dba50',
      900: '#22953e'
    }
  }
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <RouterProvider router={router} />
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
