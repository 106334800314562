import {Button, Heading, Tab, TabList, TabPanel, TabPanels, Tabs, Text} from "@chakra-ui/react";
import styles from './landing-page.module.css'
import {useNavigate} from "react-router-dom";

const tabs = [
  { header: 'Cell phone', airtableLink: 'https://airtable.com/embed/appqR9XWAVZ6BdYlA/shrLerkfMpNRwMnKa?backgroundColor=gray&viewControls=on'},
  { header: 'Internet', airtableLink: 'https://airtable.com/embed/appqR9XWAVZ6BdYlA/shr639egQlxmnWbUy?backgroundColor=gray&viewControls=on' },
  { header: 'Electricity', airtableLink: 'https://airtable.com/embed/appqR9XWAVZ6BdYlA/shrCcHmk563miewM5?backgroundColor=gray&viewControls=on' },
  { header: 'Water', airtableLink: 'https://airtable.com/embed/appqR9XWAVZ6BdYlA/shrxP8J1pSZbg9pyg?backgroundColor=gray&viewControls=on' },
  { header: 'Natural gas', airtableLink: 'https://airtable.com/embed/appqR9XWAVZ6BdYlA/shr3QnyyclMVFYRWo?backgroundColor=gray&viewControls=on' }
]

export default function LandingPage() {
  const navigate = useNavigate();

  const onShareButtonClicked = () => navigate('/share');

  return <div className={styles.landingPage}>
    <span style={{height: 60}}/>
    <Heading textAlign='center'>Empower your budget.<br/>Compare utility bills</Heading>
    <span style={{height: 20}}/>
    <Text textAlign='center'>Take control of your utility expenses and<br/>stop overpaying</Text>
    <span style={{height: 20}}/>
    <Button colorScheme='highlight' textColor='black' onClick={onShareButtonClicked}>Share a bill</Button>
    <span style={{height: 35}}/>
    <Tabs colorScheme='highlight' className={styles.tabParent} variant='soft-rounded'>
      <TabList>{
        tabs.map((tab, idx) => <Tab key={idx}>{tab.header}</Tab>)
      }</TabList>
      <TabPanels>{
        tabs.map((tab, idx) =>
          <TabPanel>
            <iframe className="airtableEmbedded"
                    loading='lazy'
                    title={tab.header}
                    key={idx}
                    src={tab.airtableLink}
                    onScroll={() => {}}
                    frameBorder="0" width="100%" height="800"></iframe>
          </TabPanel>
        )
      }</TabPanels>
    </Tabs>
    <span style={{height: 80}}/>
    <div className={styles.infoSection} id='infoSection'>
      <Text><b>At utilities.fyi our mission is <br/>to empower individuals to <br/>take control of their utility<br/>expenses
        and save money</b></Text>
      <Text width={500}>We believe in empowering individuals to take control of their utility expenses. Our platform
        provides a user-friendly interface for comparing electricity, water, gas, and other utility bills, allowing you
        to make informed decisions and optimize your spending. Whether you're a homeowner, renter, or business owner,
        our mission is to simplify the process of understanding and managing your utility costs. With [Your Company
        Name], you can navigate the complexities of utility bills with ease, uncover potential savings opportunities,
        and make smarter financial choices. Join us on your journey to greater financial empowerment and start saving on
        your utility bills today.</Text>
    </div>
    <span style={{height: 150}}/>
    <Heading size='md'>Have some feedback on how to improve the site?</Heading>
    <span style={{height: 20}}/>
    <Button colorScheme='highlight' textColor='black' >Share with us</Button>
  </div>
}
