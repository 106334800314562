import styles from './nav-bar.module.css'
import {Button, Heading} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";

export default function Navbar() {
  const navigate = useNavigate();

  const onLogoClicked = () => navigate('/');
  const onShareBillClicked = () => navigate('/share');

  const onAboutClicked = () => navigate('/#infoSection')

  return <div className={styles.navBar}>
    <Button variant='ghost' onClick={onLogoClicked}>
      <Heading size='md' >Utilities.fyi</Heading>
    </Button>
    <div className={styles.buttonRow}>
      <Button variant='link' onClick={onShareBillClicked}>Share a bill</Button>
      <Button variant='link'>Feedback</Button>
      <Button variant='link' onClick={onAboutClicked}>About</Button>
    </div>
  </div>
}
