import styles from './footer.module.css'
import {Divider, Text} from "@chakra-ui/react";

export default function Footer() {
  return <div className={styles.footer}>
    <Divider/>
    <span style={{height: 20}}/>
    <Text>Created by <a href='linkedin.com'>@Edmund Lee</a> and Developed by <a href='linkedin.com'>@Kevin
      Wang</a></Text>
  </div>
}
