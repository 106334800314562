import Navbar from "../components/nav-bar";
import {Outlet} from "react-router-dom";
import Footer from "../components/footer";
import styles from './root.module.css';
import {ScrollToAnchor} from "../components/scroll-to-anchor";

export default function Root() {
  return <div className={styles.root}>
    <ScrollToAnchor />
    <Navbar />
    <Outlet />
    <Footer />
  </div>
}
