import {Heading, Text} from "@chakra-ui/react";
import ShareCardsGrid from "../components/share-cards";
import styles from './share.module.css'

export default function Share() {
  return <div className={styles.parent}>
    <div style={{ height: 100 }}/>
    <Heading textAlign='center' >Choose what type of bill to share</Heading>
    <div style={{ height: 20 }}/>
    <Text noOfLines={[1, 2]} textAlign='center'>More bill types to be supported in the upcoming months</Text>
    <div style={{ height: 70 }}/>
    <ShareCardsGrid />
  </div>
}
