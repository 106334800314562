import styles from './share-cards.module.css';
import {Heading, Text } from "@chakra-ui/react";
import {ElectricityIcon, FireplaceIcon, InternetIcon, WaveIcon} from "./icons";

const CARDS: Array<ShareCardInfo> = [
  {
    highlightColor: '#9EFE9C',
    icon: () => <WaveIcon boxSize={6} />,
    title: 'Cell phone',
    description: 'A monthly statement from a mobile phone carrier outlining the charges for cell phone services, including voice calls, text messages, data usage, and any additional features or services such as international calling or device installment plans.',
    link: 'https://airtable.com/appqR9XWAVZ6BdYlA/pagB0ZutR72eYvNvN/form'
  },
  {
    highlightColor: '#BB9CFE',
    icon: () => <InternetIcon boxSize={6} />,
    title: 'Internet',
    description: 'A statement from an internet service provider (ISP) showing the cost of internet services, including charges for data usage, equipment rental, and any additional services.',
    link: 'https://airtable.com/appqR9XWAVZ6BdYlA/pagfXENwoibTuLqXh/form'
  },
  {
    highlightColor: '#FEEE9C',
    icon: () => <ElectricityIcon boxSize={6} />,
    title: 'Electricity',
    description: 'A statement issued by an electric utility company to consumers, detailing the cost of electricity usage within a billing period, typically measured in kilowatt-hours (kWh), along with any applicable taxes, fees, and surcharges.',
    link: 'https://airtable.com/appqR9XWAVZ6BdYlA/pagfc4X3olK2AXgXz/form'
  },
  {
    highlightColor: '#9CB7FE',
    icon: () => <WaveIcon boxSize={6} />,
    title: 'Water',
    description: 'A water utility bill is a statement from a municipality or water authority, indicating the cost of water usage. It includes charges for the volume of water consumed and maintenance of water infrastructure.',
    link: 'https://airtable.com/appqR9XWAVZ6BdYlA/pagOHC62G0wxZgz51/form'
  },
  {
    highlightColor: '#FEB99C',
    icon: () => <FireplaceIcon boxSize={6} />,
    title: 'Gas',
    description: 'A statement from a natural gas utility company, showing the charges for the consumption of natural gas used for heating or cooking within a specified billing period. Often measured in cubic feet and may also include service fees and taxes.',
    link: 'https://airtable.com/appqR9XWAVZ6BdYlA/pagMve8iYraMl4hOt/form'
  }
]

export default function ShareCardsSection() {
  return <div className={styles.cardsGrid}>{
    CARDS.map((c, idx) => <ShareCard info={c} key={idx}/>)
  }</div>
}

interface ShareCardInfo {
  highlightColor: string;
  icon: () => JSX.Element;
  title: string;
  description: string;
  link: string;
}

function ShareCard(
  props: { info: ShareCardInfo }
) {
  const { icon, title, highlightColor, description, link } = props.info;

  return <div className={styles.card} onClick={() => window.open(link) }>
    <div className={styles.highlight} style={{ background: highlightColor }}/>
    <div className={styles.cardInner}>
      <div className={styles.titleParent}>
        <>{icon()}</>
        <Heading size='md'>{title}</Heading>
      </div>
      <div style={{ height: '16px' }}></div>
      <Text fontSize='md'>{description}</Text>
    </div>
  </div>
}