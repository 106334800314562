import {createIcon} from "@chakra-ui/react";

export const WaveIcon = createIcon({
  viewBox: '0 0 28 28',
  path: (
    <path fill="#000" d="M6.995 10.5c1.649 0 2.52-.872 3.156-1.509.572-.571.853-.824 1.507-.824.655 0 .936.253 1.508.824.636.637 1.507 1.509 3.157 1.509 1.65 0 2.523-.872 3.161-1.509.573-.571.854-.824 1.511-.824.657 0 .938.253 1.511.824.637.637 1.51 1.509 3.16 1.509V8.167c-.656 0-.937-.254-1.51-.825-.637-.637-1.51-1.509-3.16-1.509-1.652 0-2.523.872-3.161 1.508-.573.572-.853.826-1.512.826-.656 0-.936-.254-1.508-.825-.635-.637-1.507-1.509-3.157-1.509-1.65 0-2.52.872-3.157 1.509-.571.571-.851.825-1.506.825-.654 0-.934-.254-1.506-.825-.636-.637-1.507-1.509-3.156-1.509v2.334c.655 0 .935.253 1.506.824.636.637 1.508 1.509 3.156 1.509Zm0 5.833c1.649 0 2.52-.871 3.156-1.508.572-.572.853-.825 1.507-.825.655 0 .936.253 1.508.825.636.637 1.507 1.508 3.157 1.508 1.65 0 2.523-.871 3.161-1.508.573-.572.854-.825 1.511-.825.657 0 .938.253 1.511.825.637.637 1.51 1.508 3.16 1.508V14c-.656 0-.937-.253-1.51-.825-.637-.637-1.51-1.508-3.16-1.508-1.652 0-2.523.871-3.161 1.507-.573.573-.853.826-1.512.826-.656 0-.936-.253-1.508-.825-.635-.637-1.507-1.508-3.157-1.508-1.65 0-2.52.871-3.157 1.508-.571.572-.851.825-1.506.825-.654 0-.934-.253-1.506-.825-.636-.637-1.507-1.508-3.156-1.508V14c.655 0 .935.253 1.506.825.636.637 1.508 1.508 3.156 1.508Zm0 5.834c1.649 0 2.52-.872 3.156-1.509.572-.572.853-.825 1.507-.825.655 0 .936.254 1.508.825.636.637 1.507 1.509 3.157 1.509 1.65 0 2.523-.872 3.161-1.509.573-.572.854-.825 1.511-.825.657 0 .938.254 1.511.825.637.637 1.51 1.509 3.16 1.509v-2.334c-.656 0-.937-.253-1.51-.824-.637-.637-1.51-1.509-3.16-1.509-1.652 0-2.523.872-3.161 1.507-.573.573-.853.826-1.512.826-.656 0-.936-.253-1.508-.824-.635-.637-1.507-1.509-3.157-1.509-1.65 0-2.52.872-3.157 1.509-.571.571-.851.824-1.506.824-.654 0-.934-.253-1.506-.824-.636-.637-1.507-1.509-3.156-1.509v2.333c.655 0 .935.254 1.506.825.636.637 1.508 1.509 3.156 1.509Z"/>
  )
})

export const InternetIcon = createIcon({
  viewBox: '0 0 28 28',
  path: [
    <path fill="#000" d="M14 7c4.127 0 7.977 1.579 10.842 4.444l1.65-1.65C23.185 6.488 18.75 4.667 14 4.667c-4.75 0-9.186 1.82-12.491 5.127l1.65 1.65C6.023 8.579 9.873 7 14 7Zm6.616 9.692c-3.586-3.587-9.646-3.587-13.232 0l1.65 1.65c2.691-2.692 7.241-2.692 9.932 0l1.65-1.65Z"/>,
    <path fill="#000" d="M23.843 13.175c-5.334-5.336-14.351-5.336-19.685 0l1.65 1.65c4.441-4.442 11.944-4.442 16.386 0l1.65-1.65ZM14 23.333a2.333 2.333 0 1 0 0-4.666 2.333 2.333 0 0 0 0 4.666Z"/>
  ]
})

export const ElectricityIcon = createIcon({
  viewBox: '0 0 28 28',
  path: (
    <path fill="#000" d="M12.833 24.5h-1.166l1.166-8.167H8.75c-1.027 0-.385-.875-.362-.91 1.505-2.66 3.769-6.626 6.79-11.923h1.167l-1.167 8.167h4.095c.467 0 .724.221.467.77-4.608 8.038-6.907 12.063-6.907 12.063Z"/>
  )
})

export const FireplaceIcon = createIcon({
  viewBox: '0 0 28 28',
  path: [
    <path fill="#000" d="M14.012 14.537c-.175.49-.175.956-.094 1.493.117.642.385 1.213.234 1.867-.152.688-.899 1.61-1.785 1.901 1.493 1.225 3.733.432 3.955-1.54.198-1.796-1.68-2.31-2.31-3.721Z"/>,
    <path fill="#000" d="M2.333 2.333v23.334h23.334V2.333H2.333ZM14 21c-1.843 0-3.465-2.193-3.5-3.57 0-.058-.012-.152-.012-.257-.151-2.018 1.167-3.733 2.882-5.098.548 1.178 1.482 2.368 2.998 3.407.677.49 1.132 1.003 1.132 2.018A3.51 3.51 0 0 1 14 21Zm9.333 2.333H21V21h-2.357a5.81 5.81 0 0 0 1.19-3.5c0-2.205-1.271-3.325-2.158-3.932-3.442-2.356-2.508-5.401-2.508-5.401-7.852 4.165-7.024 8.715-7 9.333.035 1.12.571 2.415 1.435 3.5H7v2.333H4.667V4.667h18.666v18.666Z"/>
  ]
})